import { Box, Button, Checkbox, Container, Divider, FormControlLabel, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ButtonsRow from '../ButtonsRowLatest';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Cash_Fund_T_C, DisclaimersArray } from './constant/index';
import { featureBox, featureBoxBtn, featureBoxTypo, featureMainBox } from './style';
import { containerMain, containerMainBox, HeadingStyle, linkSpan } from 'Latest/Style/Main';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'hooks/reduxHooks';
import { addCashFund, addCashFundPut, addGoal, addGoalPut, goalIdAction, goalFlowIdAction, goalFlowPathAction } from 'redux/reducers/portfolio';
import { CASH_FUND_ID, CASH_FUND_WEALTH_BASE_URL, FlowPath, mixPannelTrackerEventName } from '../../../../constants';
import Big from 'big.js';
import CustomSingleCheckBox from 'jsx/components/Shared/CustomSingleCheckBox';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LandingPageURL } from "constants/landingPageURL"
import { useAppLoading } from 'hooks/appLoading';
import Loader from 'jsx/components/Shared/Loader';
import { createInvestGoal, createInvestGoalPut, onGetBanks } from 'redux/reducers/sharedStateData';
import { getPaymentAcc, onPostUserAnswersRiskProfile } from 'redux/actions';
import FormikBooleanButtonsField from 'jsx/components/Shared/V1/FormikBooleanBtnFieldBoxV1';
import DynamicQuestionAnswers from 'Latest/2ndTierKyc/steps/Declaration/dynamicQuestion';
import CFDownloadTab from './CFDownloadTab';
import GIDownloadTab from './GIDownloadTab';
import ButtonRowV1 from 'Latest/ComponentV1/ButtonRowV1';
import { MixPannelTracker } from 'Latest/MixPanel/MixPannelTracker';
import { mainContainerV2, smallScreenBtnWrap } from 'Latest/Style/Style';

const TermCondition = ({ formik, goalId, loading, nextStep, previousStep, submitGoal }: any) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(onGetBanks());
        dispatch(getPaymentAcc())
    }, [dispatch]);

    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('md'));
    const { addGoalBody } = useAppSelector((state) => state.sharedData);
    const { serviceFee } = useAppSelector((state) => state.payment);
    const { pendingTermConditionQuestions, termConditionUserQuestions } = useAppSelector((state) => state.questionAnswer);
    const { user } = useAppSelector((state: any) => state.auth);
    const { user: { email } } = useAppSelector((state: any) => state.auth);


    const [showDeclaration, setShowDeclaration] = useState(false);
    const appLoading = useAppLoading('portfolio');

    // const termsQuestions = pendingTermConditionQuestions;
    const termsQuestions = termConditionUserQuestions?.questionAnswer;

    const intialFormikTermValue: any = {
        term1: false,
        term2: false,
        term3: false,
    }

    if (termsQuestions?.length) {
        termsQuestions?.map((item: any) => {
            intialFormikTermValue[item?.id] = ""
        })
    }

    if (pendingTermConditionQuestions?.length) {
        pendingTermConditionQuestions?.map((item: any) => {
            if (item?.answer?.isAttempted) {
                intialFormikTermValue[item?.id] = item?.answer?.answerOptions[0]?.value;

                if (item?.answer?.answerOptions[0]?.answer?.answerOptions?.length > 0) {
                    intialFormikTermValue[`${item?.id}-nested`] = item?.answer?.answerOptions[0]?.answer?.answerOptions[0]?.value
                }
            } else {
                intialFormikTermValue[item?.id] = ""
            }
        });
    }


    const generateValidationSchema = (questions: any) => {
        const validationSchema: any = {};
        validationSchema["term1"] = Yup.bool().oneOf([true], 'Required')
        validationSchema["term2"] = Yup.bool().oneOf([true], 'Required')
        // validationSchema["term3"] = Yup.bool().oneOf([true], 'Required')

        if (questions?.length > 0) {
            questions.forEach((item: any) => {
                const questionId = item?.id;
                validationSchema[questionId] = Yup.string().required('Required');
            });
        }

        return Yup.object().shape(validationSchema);
    };

    const formikTerm = useFormik({
        enableReinitialize: true,
        initialValues: intialFormikTermValue,
        onSubmit: values => {
            handleSubmitDecleration();
            nextButtonHandle()
        },
        validationSchema: generateValidationSchema(termsQuestions)
    });



    const handleSubmitDecleration = () => {
        termsQuestions?.map((value: any, index: any) => {
            const questionId = termsQuestions[index]?.id;

            const body: any = {
                investorCode: user.id,
                investorQueriesDto: [
                    {
                        id: questionId,
                        answer: {
                            answerOptions: [
                                {
                                    id: 0,
                                    value: formikTerm.values[questionId].toString()
                                }
                            ]
                        },
                        riskProfileCategoryDto: {
                            id: termsQuestions[index]?.category?.id
                        }
                    }
                ]
            };
            dispatch(onPostUserAnswersRiskProfile(body));
        });
    }

    const onSuccessGoal = (message: any, response: any) => {
        const id = message.id ? message.id : message
        formik.setFieldValue('goalIdPost', id);

        dispatch(goalIdAction(id))                       //save Goal id i.e cashfundId , GI id  
        dispatch(goalFlowIdAction(goalId))               //save Goal category i.e cashfund == 100 
        dispatch(goalFlowPathAction(FlowPath.Wizard))    //save Path i.e  wizard , invest
        nextStep();
    };

    const calculateServiceFees = () => {
        let totalAmount: any;
        let splitValue: any;
        let serviceCharge: any;
        let salesTax: any;

        const amt = addGoalBody?.initialAmount


        serviceFee?.details?.filter((item: any) => {
            if (addGoalBody?.initialAmount >= item?.minimumAmount && addGoalBody?.initialAmount <= item?.maximumAmount) {

                salesTax = new Big(Number(item?.percentageTax)).div(100).times(item?.serviceFee)
                totalAmount = new Big(amt).add(item?.serviceFee).add(salesTax).toFixed(2) // show in frontend amount field
                splitValue = totalAmount?.split(".")?.join("") // this amount goes to Nift after remove dot between values
                serviceCharge = item?.serviceFee + Number(salesTax.toFixed(2)) // this amount goes to backend as an additional charge

                formik.setFieldValue('total_serviceFees_with_amount', totalAmount)
                formik.setFieldValue('nift_amount', splitValue)
                formik.setFieldValue('serviceFees_with_salesTax', serviceCharge)

            }
        })
        return { totalAmount, splitValue, serviceCharge }
    }

    useEffect(() => {
        submitGoal()
        calculateServiceFees()
    }, [])


    const CashFundSubmit = () => {
        dispatch(addCashFund(addGoalBody, onSuccessGoal))
    }

    const nextButtonHandle = () => {
        if (goalId === CASH_FUND_ID) {
            if (formik.values.goalIdPost == '') {
                CashFundSubmit()
            } else {
                dispatch(addCashFundPut(addGoalBody, formik.values.goalIdPost))
                nextStep();

            }
        } else {
            if (formik.values.goalIdPost == '') {
                // dispatch(addGoal(addGoalBody, onSuccessGoal));
                dispatch(createInvestGoal(addGoalBody, onSuccessGoal));
            } else {
                const onSuccessInvestGoalPut = () => {
                    nextStep();
                    return
                }
                dispatch(createInvestGoalPut(addGoalBody, formik.values.goalIdPost, onSuccessInvestGoalPut));
            }
        }
        MixPannelTracker({ eventName: mixPannelTrackerEventName.accepted_terms_and_conditions, email })


    }

    const checkCashFund = goalId === CASH_FUND_ID

    const declerationQuestion = () => {
        return (
            termsQuestions?.map((value: any, index: number) => {
                let isAnsGiven = false;

                if (pendingTermConditionQuestions?.length) {
                    pendingTermConditionQuestions?.map((item: any) => {
                        if (item?.answer?.isAttempted) {
                            isAnsGiven = true;
                        } else {
                            isAnsGiven = false;
                        }
                    });
                }

                return (
                    <Box key={index}
                        sx={{
                            display: 'flex',
                            flexDirection: { md: 'row-reverse', sm: 'row-reverse', xs: 'column-reverse' },
                            alignItems: { md: 'center', sm: 'center', xs: 'flex-start' },
                            justifyContent: 'flex-end',
                            position: "relative", mb: { md: 0, sm: "20px" },
                            '& p.MuiTypography-root': { marginLeft: { md: '12px', sm: '12px', xs: 0 }, mt: { md: 0, sm: 0, xs: '24px' }, mb: 0 },
                            '& br ~.text-danger': { position: "absolute", left: 0, mt: "60px !important" },
                        }}
                    >
                        {!isAnsGiven &&
                            <DynamicQuestionAnswers
                                formik={formikTerm}
                                nestedQuestion={{}}
                                questionAnswers={value}
                            />
                        }
                    </Box>
                )
            })
        )
    };

    const buttonHtml = () => {
        return (
            <ButtonRowV1
                handleBack={() => previousStep()} handleNext={() => formikTerm.handleSubmit()}
                loadingNextBtn={loading} btnTitleNext={"Continue"}
            />
        )

    }

    return (
        <>
            <Container maxWidth={'lg'} sx={{ pt: { xs: "24px", md: "40px" }, pb: { xs: "24px", md: "100px", xl: "40px" } }} disableGutters={smScreen}>

                <Grid container>

                    <Grid item md={12} xs={12} sx={{ ...mainContainerV2 }}>

                        <Typography variant='h4' color={"#1D2939"} lineHeight={"normal"} textAlign={"center"}>Terms and conditions</Typography>

                        <Box width={"100%"}>
                            <Typography variant='h5' color={"#422E84"} mb={1}>{"Declaration"}</Typography>

                            <Box sx={{ display: "flex", gap: "16px", flexDirection: "column" }}>
                                {Cash_Fund_T_C.map((item, index: number) => (
                                    <Box key={index}>
                                        <Typography variant='body2' color="rgba(29, 41, 57, 0.70)" dangerouslySetInnerHTML={{ __html: item.para }} />
                                    </Box>
                                ))}
                            </Box>
                        </Box>

                        <Divider sx={{ width: "100%" }} />

                        <Box width={"100%"}>
                            <Typography variant='h5' color={"#422E84"} mb={1}>{"Verification charges"}</Typography>

                            <Typography variant='body2' color="rgba(29, 41, 57, 0.70)" >
                                A one-time fee of <strong style={{ color: "#422E84" }}>PKR 100</strong> will be charged for CNIC verification. This is a non-refundable fee.
                            </Typography>
                        </Box>

                        <Divider sx={{ width: "100%" }} />


                        <Box width={"100%"}>
                            <Typography variant='h5' color={"#422E84"} mb="12px">{checkCashFund ? "Mahaana Save+ Terms and Conditions" : "Disclaimers:"}</Typography>

                            <Box sx={{ display: "flex", gap: "16px", flexDirection: "column" }}>
                                {DisclaimersArray.map((item, index: number) => (
                                    <Box key={index}>
                                        {/* <Typography variant='body2' color="rgba(29, 41, 57, 0.70)">{item} {DisclaimersArray.length == index + 1 && <a href={LandingPageURL.TC} target='_blank' rel="noreferrer" style={linkSpan}>terms & conditions.</a>} </Typography> */}
                                        <Typography variant='body2' color="rgba(29, 41, 57, 0.70)" dangerouslySetInnerHTML={{ __html: item }} />

                                    </Box>
                                ))}
                            </Box>
                        </Box>

                        <Divider sx={{ width: "100%" }} />

                        <Box width={"100%"} sx={{ display: 'flex', flexDirection: "column", gap: "16px" }}>

                            <Grid item md={12} xs={12}>
                                <CustomSingleCheckBox
                                    formik={formikTerm}
                                    fieldName={`term1`}
                                    label={
                                        <div dangerouslySetInnerHTML={{ __html: "I have read the <a style='color: #422E84;text-decoration-line: underline;' href='https://mahaana.com/terms-conditions' target='_blank'>terms & conditions</a> and agree to them." }} />
                                    }
                                    type={"checkbox"}
                                    styleForm={{ paddingLeft: "0px" }}
                                    styleErr={{ marginLeft: "0px" }}
                                />
                            </Grid>

                            <Grid item md={12} xs={12}>
                                <CustomSingleCheckBox
                                    formik={formikTerm}
                                    fieldName={`term2`}
                                    label={`I confirm I am the ultimate beneficiary of my investment account.`}
                                    type={"checkbox"}
                                    styleForm={{ paddingLeft: "0px" }}
                                    styleErr={{ marginLeft: "0px" }}
                                />
                            </Grid>
                            {/*
                            Commented out because it is no longer needed because we have FATCA & CRS now.
                            <Grid item md={12} xs={12} >
                                <CustomSingleCheckBox
                                    formik={formikTerm}
                                    fieldName={`term3`}
                                    label={`I certify that I am Pakistani resident for tax purposes.`}
                                    type={"checkbox"}
                                    styleForm={{ paddingLeft: "0px" }}
                                    styleErr={{ marginLeft: "0px" }}
                                />
                            </Grid>
                             */}

                            {declerationQuestion()}

                        </Box>

                        {!smScreen && <Box sx={{ width: "100%", mt: { xs: "24px", md: "40px" } }}>
                            {buttonHtml()}
                        </Box>}

                    </Grid>

                    {smScreen && <Box sx={smallScreenBtnWrap}>
                        {buttonHtml()}
                    </Box>}

                </Grid>

                {/* <Box sx={{
                    borderRadius: "3px 3px 8px 8px",
                    background: "#FFF",
                    boxShadow: "0px 1px 4px 0px rgba(208, 213, 221, 0.25)",
                    padding: { xs: "16px", md: "20px 40px" }
                }}>
                    <Typography variant='h1' color={"#1D2939"} textAlign={"center"} mb="24px">Terms and conditions</Typography>

                    <Typography variant='h5' color={"#422E84"} mb="12px">{"Declaration"}</Typography>

                    <Box sx={{ display: "flex", gap: "16px", flexDirection: "column" }}>
                        {Cash_Fund_T_C.map((item, index: number) => (
                            <Box key={index}>
                                <Typography variant='body2' color="rgba(29, 41, 57, 0.70)" dangerouslySetInnerHTML={{ __html: item.para }} />
                            </Box>
                        ))}
                    </Box>

                    <Divider sx={{ my: { xs: "16px", md: "24px" } }} />

                    <Typography variant='h5' color={"#422E84"} mb="12px">{checkCashFund ? "Mahaana Save+ Terms and Conditions" : "Disclaimers:"}</Typography>

                    <Box sx={{ display: "flex", gap: "16px", flexDirection: "column" }}>
                        {DisclaimersArray.map((item, index: number) => (
                            <Box key={index}>
                                <Typography variant='body2' color="rgba(29, 41, 57, 0.70)" dangerouslySetInnerHTML={{ __html: item }} />

                            </Box>
                        ))}
                    </Box>

                    <Divider sx={{ my: { xs: "16px", md: "24px" } }} />

                    <Box sx={{ display: 'flex', flexDirection: "column", gap: "16px" }}>

                        <Grid item md={12} xs={12}>
                            <CustomSingleCheckBox
                                formik={formikTerm}
                                fieldName={`term1`}
                                label={
                                    <div dangerouslySetInnerHTML={{ __html: "I have read the <a style='color: #422E84;text-decoration-line: underline;' href='https://mahaana.com/terms-conditions' target='_blank'>terms & conditions</a> and agree to them." }} />
                                }
                                type={"checkbox"}
                                styleForm={{ paddingLeft: "0px" }}
                                styleErr={{ marginLeft: "0px" }}
                            />
                        </Grid>

                        <Grid item md={12} xs={12}>
                            <CustomSingleCheckBox
                                formik={formikTerm}
                                fieldName={`term2`}
                                label={`I confirm I am the ultimate beneficiary of my investment account.`}
                                type={"checkbox"}
                                styleForm={{ paddingLeft: "0px" }}
                                styleErr={{ marginLeft: "0px" }}
                            />
                        </Grid>

                        <Grid item md={12} xs={12} >
                            <CustomSingleCheckBox
                                formik={formikTerm}
                                fieldName={`term3`}
                                label={`I certify that I am Pakistani resident for tax purposes.`}
                                type={"checkbox"}
                                styleForm={{ paddingLeft: "0px" }}
                                styleErr={{ marginLeft: "0px" }}
                            />
                        </Grid>

                        {declerationQuestion()}

                    </Box>

                    {!smScreen && <Box sx={{ mt: { xs: "24px", md: "40px" } }}>
                        {buttonHtml()}
                    </Box>}

                </Box>

                {smScreen && <Box sx={{ p: "8px 16px", mt: "24px", background: "white" }}>
                    {buttonHtml()}
                </Box>} */}
            </Container>



            {/* <Container maxWidth={'md'} sx={containerMain}  >
                {appLoading && <Loader />}
                <Box sx={containerMainBox}>
                    <Grid container spacing={2} >
                        <Grid item md={12} xs={12} sx={{ py: "2rem", pr: "1.5rem" }} >

                            <Container sx={{ ml: "1rem", minHeight: "30rem", mt: "2rem", mb: "2rem" }} >
                                <Box>
                                    <Grid container spacing={2}>
                                        <Grid item md={12} xs={12} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                            <Typography sx={HeadingStyle}>Conditions</Typography>
                                        </Grid>

                                        <Grid item md={12} xs={12} sx={{ display: 'flex', justifyContent: 'flex-start', mt: 1 }}>
                                            <Typography sx={{ ...HeadingStyle, fontSize: "24px" }}>{"Declaration"}</Typography>
                                        </Grid>

                                        <Grid item md={12} xs={12} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                            <Stack component="ul" spacing={2} sx={{ pl: 1 }}>
                                                {Cash_Fund_T_C.map((item, index: number) => (
                                                    <Stack
                                                        key={item.para}
                                                        alignItems="center"
                                                        component="li"
                                                        direction="row"
                                                        spacing={1.5}
                                                        sx={{ alignItems: "baseline" }}
                                                    >
                                                        <FiberManualRecordIcon sx={{ fontSize: "xx-small", color: "black" }} />
                                                        {index == Cash_Fund_T_C.length - 1 ?
                                                            declerationQuestion()
                                                            :
                                                            <Typography sx={{ ...HeadingStyle, fontWeight: 400, fontSize: "16px", textAlign: 'left' }}>
                                                                {item.para} &nbsp;
                                                                {item.link && <span style={{ fontWeight: 700, lineHeight: "155%", color: "#43BED8", cursor: "pointer" }}>{item.link}</span>}
                                                            </Typography>
                                                        }

                                                    </Stack>
                                                ))}
                                            </Stack>
                                        </Grid>


                                    </Grid>

                                    <Divider sx={{ my: 4 }} />

                                    <Grid container spacing={2}>
                                        <Grid item md={12} xs={12} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                            <Typography sx={{ ...HeadingStyle, fontSize: "24px" }}>{checkCashFund ? "Mahaana Save+ Terms and Conditions" : "Disclaimers:"}</Typography>
                                        </Grid>

                                        <Grid item md={12} xs={12} sx={{ display: 'flex', justifyContent: 'flex-start', }}>
                                            <Stack component="ul" spacing={2} sx={{ pl: 1 }}>
                                                {DisclaimersArray.map((item: string, index: number) => (
                                                    <Stack
                                                        key={item}
                                                        alignItems="center"
                                                        component="li"
                                                        direction="row"
                                                        spacing={1.5}
                                                        sx={{ alignItems: "baseline" }}
                                                    >
                                                        <FiberManualRecordIcon sx={{ fontSize: "xx-small", color: "black" }} />
                                                        <Typography sx={{
                                                            ...HeadingStyle, fontWeight: 400,
                                                            fontSize: "16px", textAlign: 'left'
                                                        }}>{item} {DisclaimersArray.length == index + 1 && <a href={LandingPageURL.TC} target='_blank' rel="noreferrer" style={linkSpan}>terms and conditions.</a>} </Typography>
                                                    </Stack>
                                                ))}
                                            </Stack>
                                        </Grid>

                                    </Grid>

                                    <Grid container spacing={2} sx={{ mt: 1 }}>
                                        <Grid item md={12} xs={12} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                            <Typography sx={{ ...HeadingStyle, fontSize: "24px" }}>MICF Information:</Typography>
                                        </Grid>
                                        <CFDownloadTab />

                                        {!checkCashFund &&
                                            <>
                                                <Grid item md={12} xs={12} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                    <Typography sx={{ ...HeadingStyle, fontSize: "24px" }}>MIIETF Information:</Typography>
                                                </Grid>
                                                <GIDownloadTab />
                                            </>
                                        }


                                        <Grid item md={12} xs={12}>
                                            <CustomSingleCheckBox
                                                formik={formikTerm}
                                                fieldName={`term1`}
                                                label={`I have read the terms and conditions and agree to them.`}
                                                type={"checkbox"}
                                                styleForm={{ paddingLeft: "0px" }}
                                                styleErr={{ marginLeft: "0px" }}
                                            />
                                        </Grid>

                                        <Grid item md={12} xs={12} >
                                            <CustomSingleCheckBox
                                                formik={formikTerm}
                                                fieldName={`term2`}
                                                label={`I certify that I am Pakistani resident for tax purposes.`}
                                                type={"checkbox"}
                                                styleForm={{ paddingLeft: "0px" }}
                                                styleErr={{ marginLeft: "0px" }}
                                            />

                                        </Grid>

                                    </Grid>

                                </Box>
                            </Container>

                            <ButtonsRow
                                backButtonOnClick={(e: any) => {
                                    e.preventDefault();
                                    previousStep();
                                }}
                                loading={loading}
                                // nextButtonDisabled={!(formikTerm.values.term1 && formikTerm.values.term2)}
                                nextButtonOnClick={(e: any) => {
                                    e.preventDefault();
                                    // nextButtonHandle()
                                    formikTerm.handleSubmit()
                                }}
                            />

                        </Grid>
                    </Grid>
                </Box>
            </Container > */}
        </>
    )
}

export default TermCondition