/* eslint-disable react-hooks/rules-of-hooks */
import Big from 'big.js';
import * as constants from './settings.js';
import { onGetAccountSetup, UserDetails } from 'redux/actions';
import { onGetMarketData, onGetNotifications } from 'redux/reducers/sharedStateData';
import { useAppDispatch } from 'hooks/reduxHooks';
import Ellipses from "../Latest/Images/Ellipses.png"
import Ellipses2 from "../Latest/Images/Ellipses2.png"
import Ellipses3 from "../Latest/Images/Ellipses3.png"

export const USER_MANAGER_BASE_URL = constants.USER_MANAGER_BASE_URL;
export const ACCOUNT_MANAGEMENT_BASE_URL = constants.ACCOUNT_MANAGEMENT_BASE_URL;
export const CASH_FUND_WEALTH_BASE_URL = constants.CASH_FUND_WEALTH_BASE_URL;
export const RISK_PROFILE_BASE_URL = constants.RISK_PROFILE_BASE_URL;
export const PORTFOLIO_BASE_URL = constants.PORTFOLIO_BASE_URL;
export const STOCK_DETAIL_BASE_URL = constants.STOCK_DETAIL_BASE_URL;
export const EXCEL_UPLOADER = constants.EXCEL_UPLOAD_BASE_URL;
export const WEALTH_API_BASE_URL = constants.WEALTH_API_BASE_URL;
export const COMMON_SYNC_CONNECTIONS = constants.COMMON_SYNC_CONNECTIONS;
export const INVITE_MAHANIERS_BASER_URL = constants.INVITE_MAHANIERS_BASER_URL;
export const PAYMENT_GATEWAY_BASER_URL = constants.PAYMENT_GATEWAY_BASER_URL;
export const WEALTH_RAAST_PAYMENT_BASER_URL = constants.WEALTH_RAAST_PAYMENT_BASER_URL;


// export const USER_MANAGER_BASE_URL = "https://dev-mahaana-common-usermanager.azurewebsites.net";
// export const ACCOUNT_MANAGEMENT_BASE_URL = "https://dev-mahaana-dfa-accountmanagement.azurewebsites.net";
// export const RISK_PROFILE_BASE_URL = "https://dev-mahaana-dfa-riskprofile.azurewebsites.net";
// export const PORTFOLIO_BASE_URL = "https://dev-mahaana-wealth-stockmanager-reconcile.azurewebsites.net";
// export const STOCK_DETAIL_BASE_URL = "https://dev-mahaana-wealth-equitymanager.azurewebsites.net"

export const bankSavingsRate = new Big(0.096); /* 0.0752 */
export const mahaanaSaveRate = new Big(0.2); /* 0.2313 */ /* 0.2129 */

export enum InputCategoryEnum {
  CheckBox = 'CheckBox',
  DropDownSelection = 'DropDownSelection',
  RadioButton = 'RadioButton',
  TextBox = 'TextBox',
  BooleanButtons = "BooleanButtons"
}

export enum AnswerFieldType {
  TextBox = 1,
  RadioButton = 2,
  CheckBox = 3,
  DropDownSelection = 4
}

export enum FieldPattern {
  String = 'abc123',
  Number = '0000',
  Alphabets = 'abc',
  Date = 'dd/mm/yyyy',
  TextArea = "textArea"
}

export enum QuestionAnswerCategoryEnum {
  SignUp = 1,
  RiskProfile = 2,
  AccountSetup = 3,
  Payment = 4,
  CashFund = 6,

  TermCondition = 8,
  Kyc = 9,
  cashFundRiskProfile = 10
}

export enum PendingQuestionAnswerCategoryEnum {
  SignUp = 'SignUp',
  RiskProfile = 'RiskProfile',
  AccountSetup = 'AccountSetup',
  Payment = 'Payment',
  SpecificGoalScore = 'SpecificGoalScore',
  CashFund = 'CashFund',
  TermCondition = 'CashFundConditionsDeclaration'
}

export enum GoalIdEnum {
  Vps = 0,
  Ops = 1,
  HouseBuy = 2,
  CollegeFee = 3,
  WindFallReceive = 4,
  TravelTime = 5,
  LargeExpense = 6,
  IncreaseWealth = 7,
  Travel = 8,

  MahaanaCashAccount = 100
}

export enum GoalValueEnum {
  Vps = 'Vps',
  Ops = 'Ops',
  HouseBuy = 'HouseBuy',
  CollegeFee = 'Education',
  WindFallReceive = 'Other',
  TravelTime = 'Travel',
  LargeExpense = 'LargeExpense',
  IncreaseWealth = "IncreaseWealth",
  Travel = 'Travel',

  MahaanaCashAccount = 'MahaanaCashAccount'
}

export const GoalNameEnum: any = {
  Vps: 'Voluntary Pension',
  Ops: 'Occupational Pension',
  HouseBuy: 'Buy a House',
  CollegeFee: 'Plan for education',
  WindFallReceive: 'Others',
  TravelTime: 'Plan for travel',
  LargeExpense: 'Large Expense',
  IncreaseWealth: "Increase Wealth",
  Travel: 'Plan for travel',

  MahaanaCashAccount: 'Mahaana cash account'
}

export const goalCategory: any = {
  CashFund: 'CashFund',
  GeneralInvest: "GeneralInvestment"
}

export const Goals = {
  VoluntaryPension: 0,
  Ops: 1,
  HouseBuy: 2,
  CollegeFee: 3,
  WindFallReceive: 4,
  TravelTime: 5,
  LargeExpense: 6,
  IncreaseWealth: 7,
  Travel: 8,


  MahaanaCashAccount: 100
};

export const AuthMsgs = {
  emailVerify: 'Please verify email address.',
  forgotPassMsg: 'An email has been sent to your account for password reset.',
  inActiveUserMsg: 'You are not an active user.',
  otpVerify: 'Please verify mobile number.',
  passwordResetSuccessfully: 'Your Password is changed successfully.',
  registeredSuccessfuly: 'Registered successfuly',
  resendOtpSuccessMsg: 'OTP is sent to your Registered Mobile Number',
  resendOtpWhatsapp: "OTP sent successfully",
};

export const PLAN_TYPES = {
  ACCUMULATE_AMOUNT: 'AccumulateAmount',
  MONTHLY_CONTRIBUTIONS: "MonthlyContributions"
};

export const CASH_FUND_ID = 100
export const GENERAL_INVEST_ID = 200



export const Env_Name = {
  dev: 'dev',
  stg: "stg",
  prod: "prod",
};

export const transactionTableStatus: any = {
  ApprovalPending: {
    label: "Pending",
    color: "rgb(183, 110, 0)",
    bg: "rgba(255, 171, 0, 0.16)"
  },
  ApprovalSucceeded: {
    label: "Verified",
    color: "rgb(27, 128, 106)",
    bg: "rgba(54, 179, 126, 0.16)"
  },
  ApprovalFailed: {
    label: "Failed",
    color: "rgb(183, 29, 24)",
    bg: "rgba(255, 86, 48, 0.16)"
  },
  ApprovalInProgress: {
    label: "In Progress",
    color: "rgb(183, 110, 0)",
    bg: "rgba(255, 171, 0, 0.16)"
  },
  Pending: {
    label: "Pending",
    color: "rgb(183, 110, 0)",
    bg: "rgba(255, 171, 0, 0.16)"
  },
  Succeeded: {
    label: "Verified",
    color: "rgb(27, 128, 106)",
    bg: "rgba(54, 179, 126, 0.16)"
  },
  Failed: {
    label: "Failed",
    color: "rgb(183, 29, 24)",
    bg: "rgba(255, 86, 48, 0.16)"
  },
  InProgress: {
    label: "In Progress",
    color: "rgb(183, 110, 0)",
    bg: "rgba(255, 171, 0, 0.16)"
  }
}

export const failTransactionsStatus: any = {
  ApprovalPending: {
    label: "Verification pending",
    color: "#1D2939B2",
    bg: "#DEE3E7"
  },
  Pending: {
    label: "Verification pending",
    color: "#1D2939B2",
    bg: "#DEE3E7"
  },
  DepositPending: {
    label: "Deposit pending",
    color: "#7A4510",
    bg: "#FCDEC0"
  },
  // ApprovalForceFailed: {
  //   label: "Failed",
  //   color: "rgb(183, 29, 24)",
  //   bg: "rgba(255, 86, 48, 0.16)"
  // },
  // ApprovalFailed: {
  //   label: "Failed",
  //   color: "rgb(183, 29, 24)",
  //   bg: "rgba(255, 86, 48, 0.16)"
  // },
  ApprovalInProgress: {
    label: "In Progress",
    color: "rgb(183, 110, 0)",
    bg: "rgba(255, 171, 0, 0.16)"
  },
  // Failed: {
  //   label: "Failed",
  //   color: "rgb(183, 29, 24)",
  //   bg: "rgba(255, 86, 48, 0.16)"
  // },
  InProgress: {
    label: "In Progress",
    color: "rgb(183, 110, 0)",
    bg: "rgba(255, 171, 0, 0.16)"
  }
}

export const transactionTableStatusV2: any = {
  ApprovalPending: {
    label: "Verification pending",
    color: "#1D2939B2",
    bg: "#DEE3E7"
  },
  Pending: {
    label: "Verification pending",
    color: "#1D2939B2",
    bg: "#DEE3E7"
  },
  DepositPending: {
    label: "Deposit pending",
    color: "#7A4510",
    bg: "#FCDEC0"
  },
  ApprovalForceFailed: {
    label: "Failed",
    color: "rgb(183, 29, 24)",
    bg: "rgba(255, 86, 48, 0.16)"
  },
  ApprovalSucceeded: {
    label: "Verified",
    color: "rgb(27, 128, 106)",
    bg: "rgba(54, 179, 126, 0.16)"
  },
  ApprovalFailed: {
    label: "Failed",
    color: "rgb(183, 29, 24)",
    bg: "rgba(255, 86, 48, 0.16)"
  },
  ApprovalInProgress: {
    label: "In Progress",
    color: "rgb(183, 110, 0)",
    bg: "rgba(255, 171, 0, 0.16)"
  },
  Succeeded: {
    label: "Verified",
    color: "rgb(27, 128, 106)",
    bg: "rgba(54, 179, 126, 0.16)"
  },
  Failed: {
    label: "Failed",
    color: "rgb(183, 29, 24)",
    bg: "rgba(255, 86, 48, 0.16)"
  },
  InProgress: {
    label: "In Progress",
    color: "rgb(183, 110, 0)",
    bg: "rgba(255, 171, 0, 0.16)"
  },
  Abandon: {
    label: "Deletion in progress",
    color: "#6F2020",
    bg: "#FCD9D9"
  }
}

export const transactionDashboardStatus: any = {
  ApprovalPending: {
    label: "Pending transactions",
    color: "rgb(183, 110, 0)",
    bg: "rgba(255, 171, 0, 0.16)"
  },
  ApprovalSucceeded: {
    label: "Verified",
    color: "rgb(27, 128, 106)",
    bg: "rgba(54, 179, 126, 0.16)"
  },
  ApprovalFailed: {
    label: "Failed transactions",
    color: "rgb(183, 29, 24)",
    bg: "rgba(255, 86, 48, 0.16)"
  },
  ApprovalInProgress: {
    label: "Transaction in progress",
    color: "rgb(183, 110, 0)",
    bg: "rgba(255, 171, 0, 0.16)"
  }
}

export const labelTableStatus: any = {
  ApprovalPending: { label: "Pending", status: "pending" },
  ApprovalSucceeded: { label: "Verified", status: "success" },
  ApprovalFailed: { label: "Failed", status: "failed" },
  ApprovalInProgress: { label: "In Progress", status: "progress" }
}

export const zakatStatus: any = {
  Default: { label: "Default", status: "Default", isSubmit: false },
  Pending: { label: "Pending", status: "pending", isSubmit: true },
  Approved: { label: "Verified", status: "success", isSubmit: true },
  Rejected: { label: "Failed", status: "failed", isSubmit: false },
  InProgress: { label: "Verification in progress", status: "progress", isSubmit: true }
}

export enum KycStatus {
  Default = "Default",
  ApprovalPending = "ApprovalPending",
  ApprovalSucceeded = "ApprovalSucceeded",
  ApprovalFailed = "ApprovalFailed",
  ApprovalInProgress = "ApprovalInProgress",
  ApprovalHalted = "ApprovalHalted",
  ApprovalVerified = "ApprovalVerified",
}

export const transactionTypeArrow: any = {
  Bonus: "down",
  DividendReInvest: "down",
  CashDividend: "up",
  Dividend: "up",
  Withdrawal: "up",
  Investment: "down",
  Salary: "down",
  ReversalWithdrawal: "down",
  NadraFee: "up",
  MobileVerificationFee: "up",
  ProcessingFee: "up",
}

export const transactionTypeArrowSigns: any = {
  Bonus: " ",
  DividendReInvest: "+",
  CashDividend: "-",
  Dividend: "-",
  Withdrawal: "-",
  Investment: "+",
  Salary: "+",
  ReversalWithdrawal: "+",
  NadraFee: "-",
  MobileVerificationFee: "-",
  ProcessingFee: "-",

}

export const PaymentMethodType: any = {
  PayMob: "Paymob",
  NIFT: "NIFT",
  IBFT: "IBFT",
}

export const FlowPath: any = {
  Wizard: "Wizard",
  Invest: "Invest",
  Cashfund: "Cashfund"
}
export const marketDataTableChange: any = {
  "USD PKR Interbank Selling": { label: "USD", unit: "PKR" },
  "Gold 10 Grams PKR": { label: "Gold (10g)", unit: "PKR" },
  "Crude Oil USD/Barrel": { label: "Crude oil", unit: "$" },
  "Kibore, 3 Month Ask": { label: "KIBOR", unit: "" },
}


export const passwordSchema = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&^+-/\\(){}\[\]=<>\.,:;'\"`~_|])[A-Za-z\d@$!%*#?&^+-/\\(){}\[\]=<>\.,:;'\"`~_|]{8,}$/


export const depositDetailCFArr = [
  { title: "Bank Name", value: "Faysal Bank Limited" },
  { title: "Account Title", value: "Central Depositary Company of Pakistan Trustee Mahaana Islamic Cash Plan" },
  // { title: "Account Title", value: "CDC TRUSTEE MAHAANA ISLAMIC CASH PLAN" },
  // { title: "Account Number", value: "3554390000003770" },
  { title: "IBAN", value: "PK77FAYS3554390000003770" },
]

export const depositDetailGIArr = [
  { title: "Bank Name", value: "JS Bank Limited" },
  { title: "Account Title", value: "JS Global Capital Limited" },
  // { title: "Account Number", value: "-" },
  { title: "IBAN", value: "PK73JSBL9005000000130612" },
]

export const pieColors = ['#FF7D84', '#FFCED0', '#62529B', '#D1E9FF', '#53B1FD'];

export const colorPortfolioCard = ["#79B753", "#ECAD71", "#70C4C4", "#8A79DF", "#3A6FAD"]

export const pieColorHsl = ["hsl(97, 41%, 82%)", "hsl(29, 76%, 98%)", "hsl(180, 42%, 90%)", "hsl(250, 61%, 97%)", "hsl(212, 50%, 75%)"]

export const savePieColors = ["#422E84", "#0091AD", "#BC96E6", "#3A6EAC", "#8278DE", "#6FC3C3", "#EFAC6C", "#79B752"]
export const savePieColorsHsl = ["hsla(254, 48%, 35%, 1)", "hsla(269, 62%, 75%, 1)", "hsla(190, 100%, 34%, 1)", "hsla(212, 50%, 45%, 1)", "hsla(246, 61%, 67%, 1)", "hsla(180, 41%, 60%, 1)", "hsla(29, 80%, 68%, 1)", "hsla(97, 41%, 52%, 1)"]
export const savePieColorsHslDeselected = [
  "hsla(254, 25%, 87%, 1)", "hsla(269, 62%, 75%, 0.2)",
  "hsla(190, 100%, 34%, 0.2)", "hsla(212, 50%, 75%, 1)",
  "hsla(250, 61%, 97%, 1)", "hsla(180, 42%, 90%, 1)",
  "hsla(29, 76%, 98%, 1)", "hsla(97, 41%, 82%, 1)"
]

export const intialInvestmentMaxLimit = 800000;

export const mixPannelTrackerEventName = {
  address_submitted: "address_submitted",
  address_submitted_fail: 'address_submitted_fail',
  cnic_images_uploaded: 'cnic_images_uploaded',
  projections_reviewed: 'projections_reviewed',
  reviewed_submission: 'reviewed_submission',
  accepted_terms_and_conditions: "accepted_terms_and_conditions",
  cnic_info_submitted: "cnic_info_submitted",
  otp_Failed: "otp_Failed",
  otp_verified: "otp_verified",
  otp_Resend_Code_Fail: "otp_Resend_Code_Fail",
  otp_Resend_Code_Success: "otp_Resend_Code_Success",
  save_plus_started: "save_plus_started",
  general_invest_started: 'general_invest_started',
  bank_vs_save_plus_experimented: 'bank_vs_save_plus_experimented',
  signed_up_on_app: "signed_up_on_app",
  initial_transaction_submitted: "initial_transaction_submitted ",
  tenK_crossed: "10k_crossed",
  something_went_wrong: "something_went_wrong_page",
  email_verified: "email_verified",
  View_Detail: "view_detail_error",
}

export const passwordMaxLength = {
  maxLength: "20"
}

export const selectReasonEnum: any = {
  "Does not issue TIN to its Residents.": {
    reason: "NonIssuerCountry"
  },
  "The Account Holder is otherwise unable to obtain a TIN.": {
    reason: "NotAvailableTin"
  },
  "No TIN is required.": {
    reason: "NotRequired"
  }
}

export const reasonSelected: any = {
  "NonIssuerCountry": {
    reason: "Does not issue TIN to its Residents."
  },
  "NotAvailableTin": {
    reason: "The Account Holder is otherwise unable to obtain a TIN."
  },
  "NotRequired": {
    reason: "No TIN is required."
  },
  "Default": {
    reason: ""
  }
}

export const FatcaShowStatus = [
  "ApprovalSucceeded",
  "Succeeded"
]

export enum accountStatementStatus {
  progressStatus = "InProgress",
  completedStatus = "Completed"
}



export const DashboardApisCaller = () => {

  const dispatch = useAppDispatch();

  const onCallApis = () => {
    dispatch(UserDetails());
    dispatch(onGetAccountSetup());
    dispatch(onGetMarketData());
    dispatch(onGetNotifications());
  };

  return {
    onCallApis,
  };
};


export const FinancialAdvisors = {
  "CEO": {
    advisor: "CEO",
    name: "Muhammad Shamoon Tariq (CFA, FDP)",
    title: "Chief Executive Officer & Co-Founder",
    description: "Muhammad Shamoon Tariq, CEO of Mahaana Wealth, brings over 13 years of global investment expertise, specializing in Emerging and Frontier markets. As a former Partner at Tundra Fonder, he managed top-rated funds and pioneered the Tundra Sustainable Frontier Fund. Ranked among the top 3 global investment managers by Citywire UK, Shamoon’s stewardship spans pivotal markets like Pakistan, Vietnam, and Egypt. His deep market knowledge, CFA charter, and innovative vision are revolutionizing Pakistan's financial sector.",
    email: "shamoon.tariq@mahaana.com",
    phoneNo: "+92 332 9234413",
    whatsappNo: "+92 332 9234413",
    image: Ellipses2,
    bookingLink: "https://outlook.office365.com/book/Shamoonsbooking@Mahaana.com/",
    linkedinLink: "https://www.linkedin.com/in/muhammad-shamoon-tariq-cfa-fdp-24b40a80?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    phoneLink: "tel:923329234413",
    whatsappLink: "https://wa.me/923329234413",

  },
  "CIO": {
    advisor: "CIO",
    name: "Mubashir Zuberi (CFA)",
    title: "Chief Investment Officer",
    description: "Mubashir Zuberi, Chief Investment Officer at Mahaana Wealth, brings extensive expertise in frontier and emerging markets. Holding a CFA charter and a bachelor's in financial management from SZABIST, he previously served as a Senior Analyst at Tundra Fonder AB. Mubashir's strategic insights and experience in evaluating investment opportunities are pivotal to Mahaana's innovative investment products and strategies.",
    email: "mubashir.zuberi@mahaana.com",
    phoneNo: "+92 333 3881593",
    whatsappNo: "+92 333 3881593",
    image: Ellipses3,
    bookingLink: "https://outlook.office365.com/book/Mubashirsbookings@Mahaana.com/",
    linkedinLink: "https://www.linkedin.com/in/mubashir-zuberi-cfa?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    phoneLink: "tel:923333881593",
    whatsappLink: "https://wa.me/923333881593",

  },
  "SO": {
    advisor: "SO",
    name: "Mohammad Ali Saeed (FCM, SBC, PMR) IFMP",
    title: "Chief Strategy & Sales Officer",
    description: "Mohammad Ali Saeed has been associated with investment banking, equities brokerage and asset management industry for the past 2 decades. Over this time he has served on 3 advisory committees at the Pakistan Stock Exchange on pioneering products and portfolio solutions. He is also a management consultant who has advised over 50 corporations in Pakistan and the MENA region on the people and process architecture. His areas of expertise are financial modelling, corporate finance and portfolio management. His diverse expertise and innovative approach make him a leading figure in the financial sector.",
    email: "ali.saeed@mahaana.com",
    phoneNo: "+92 321 2888143",
    whatsappNo: "+92 321 2888143",
    image: Ellipses,
    bookingLink: "https://outlook.office365.com/owa/calendar/Alisbookings@Mahaana.com/bookings/",
    linkedinLink: "https://www.linkedin.com/in/mohammad-ali-saeed-5b445523?utm_source=share&utm_campaign=share_via&utm_content`=profile&utm_medium=android_app",
    phoneLink: "tel:923212888143",
    whatsappLink: "https://wa.me/923212888143",

  },
  "NULL": {
    advisor: "NULL"
  }
};